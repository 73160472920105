import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/browser";
import { deleteNotification, unregisterFcmToken } from "@/util/fcm-notification-helper";
import { CaregiverResponse } from "../openapi";
import { handleApiError } from "../util/error-handlers";
import { deviceType } from "@/util/helper";
import { useLocation, useNavigate } from "react-router-dom";
import { getCurrentUser, fetchUserAttributes, signOut, fetchAuthSession } from "aws-amplify/auth";
import { getCaregiverService } from "@/util/api-helper";
import ErrorInfo from "./errorInfo";

const isSessionValid = async () => {
  try {
    const session = await fetchAuthSession();
    if (!session.tokens || !session.tokens.idToken) {
      return false;
    }
    return session.tokens.idToken.payload.exp && session.tokens.idToken.payload.exp * 1000 > Date.now();
  } catch (e) {
    return false;
  }
};

export interface Me {
  username: string;
  userId: string;
  attributes: {
    "custom:firstName": string;
    "custom:lastName": string;
    email: string;
    email_verified: boolean;
    phone_number: string;
    phone_number_verified: boolean;
    sub: string;
    given_name?: string;
    family_name?: string;
  };
  cognitoGroups: string[];
}

export interface IMeContext {
  data?: Me;
  logout?: () => Promise<void>;
  caregiverData?: CaregiverResponse;
  refreshCaregiverData?: () => void;
  refreshUser?: () => Promise<void>;
  loading?: boolean;
  lastLocationUpdateTime?: Date;
  setLastLocationUpdateTime?: (d: Date) => void;
}

export const MeContext = React.createContext<IMeContext>({});

interface MeProviderProps {
  children: React.ReactNode;
}

export const MeProvider: React.FC<MeProviderProps> = (props) => {
  const [me, setMe] = useState<Me>();
  const [loading, setLoading] = useState(false);
  const [lastLocationUpdateTime, setLastLocationUpdateTime] = useState<Date>(new Date());
  const [caregiverData, setCaregiverData] = useState<CaregiverResponse | undefined>(undefined);
  const [showError, setShowError] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const loadCurrentUser = useCallback(async (bypassCache = false) => {
    try {
      const session = await fetchAuthSession({ forceRefresh: bypassCache });
      const groups = session.tokens?.idToken?.payload["cognito:groups"] as string[];
      const user = await getCurrentUser();
      if (!user) {
        throw new Error("User not found");
      }
      const userAttributes = await fetchUserAttributes();
      if (!userAttributes.email) {
        throw new Error("Email not found");
      }
      setMe({
        username: user.username,
        userId: user.userId,
        attributes: {
          "custom:firstName": userAttributes["custom:firstName"]!,
          "custom:lastName": userAttributes["custom:lastName"]!,
          email: userAttributes.email,
          email_verified: userAttributes.email_verified === "true",
          phone_number: userAttributes.phone_number!,
          phone_number_verified: userAttributes.phone_number_verified === "true",
          sub: userAttributes.sub!,
          given_name: userAttributes.given_name,
          family_name: userAttributes.family_name,
        },
        cognitoGroups: groups ?? [],
      });
      if (user) {
        Sentry.getGlobalScope().setUser({
          id: user.userId,
          username: user.username,
          email: userAttributes.email,
        });
      }
    } catch (e) {
      const error = e as Error;
      setShowError(true);
      console.error(error);
      const { message } = error;
      error.message = `Unable to get caregiver data. ${message}`;
      throw error;
    }
  }, []);

  const refreshUser = useCallback(async () => {
    await loadCurrentUser(true);
  }, [loadCurrentUser]);

  const refreshCaregiverData = useCallback(async () => {
    try {
      const caregiverService = await getCaregiverService();
      const caregiverData = (await caregiverService.getCaregiver()).data;
      setCaregiverData(caregiverData);
    } catch (error) {
      handleApiError(error);
    }
  }, []);

  const logout = useCallback(async () => {
    Sentry.getGlobalScope().setUser(null);
    // delete fcm token on logout
    await deleteNotification();
    if (deviceType === "browser") {
      await unregisterFcmToken();
    } else {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: "logout" }));
    }
    await signOut();
    navigate("/welcome", { replace: true });
  }, [navigate]);

  const isNotDashboardPage = useMemo(
    () =>
      [
        "/forgot-password",
        "/reset-password",
        "/on-boarding/verification",
        "/on-boarding/childs-name",
        "/on-boarding/childs-birthday",
        "/on-boarding/phone-credentials",
        "/on-boarding/block-time",
        "/on-boarding/default-time",
        "/on-boarding/default-apps",
        "/sign-up-child",
        "/sign-up-child/naming",
        "/sign-up-child/birthdate",
        "/sign-up-child/credentials",
        "/sign-up-child/block-time",
        "/sign-up-child/default-time",
        "/sign-up-child/default-apps",
        "/login",
        "/welcome",
      ].includes(location.pathname),
    [location.pathname],
  );

  const redirectUnauthenticatedUser = useCallback(async () => {
    if (!isNotDashboardPage) {
      let redirectPath = "/welcome";
      if (location.pathname !== "/") {
        const searchParams = new URLSearchParams();
        searchParams.set("redirect", location.pathname);
        redirectPath = `/login?${searchParams.toString()}`;
      }
      navigate(redirectPath, { replace: true });
    }
  }, [isNotDashboardPage, navigate, location.pathname]);

  const loadCaregiver = useCallback(async () => {
    if (caregiverData || loading) return;
    setLoading(true);
    try {
      await loadCurrentUser(true);
      await refreshCaregiverData();
    } catch (error) {
      redirectUnauthenticatedUser();
      return;
    } finally {
      setLoading(false);
    }
  }, [caregiverData, loadCurrentUser, loading, refreshCaregiverData, redirectUnauthenticatedUser]);

  useEffect(() => {
    let mounted = true;
    (async () => {
      const isLoggedIn = await isSessionValid();
      if (!mounted) return;
      if (!isLoggedIn) {
        redirectUnauthenticatedUser();
        return;
      }
      await loadCaregiver();
    })();

    return () => {
      mounted = false;
    };
  }, [loadCaregiver, redirectUnauthenticatedUser]);

  const value = useMemo(
    () => ({
      data: me,
      refreshUser,
      loading,
      lastLocationUpdateTime,
      setLastLocationUpdateTime,
      logout,
      caregiverData,
      refreshCaregiverData,
    }),
    [caregiverData, lastLocationUpdateTime, loading, logout, me, refreshCaregiverData, refreshUser],
  );

  return (
    <MeContext.Provider value={value}>
      {value.data && !value.loading ? props.children : showError ? <ErrorInfo /> : null}
    </MeContext.Provider>
  );
};

export default MeProvider;
