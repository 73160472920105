import React, { useState } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Toolbar,
  ButtonProps,
  SxProps,
  Theme,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { pinwheelPalette } from "../../styles/theme";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  showModal: boolean;
  setShowModal: (b: boolean) => void;
  headerText: string | React.ReactNode;
  bodyText?: string;
  renderBody?: () => React.ReactNode;
  actionButtonType?: ButtonProps["color"];
  actionButtonText?: string;
  onActionButtonPress?: () => Promise<boolean | void>;
  cancelButtonText?: string;
  onCancelButtonPress?: () => Promise<void> | void;
  confirmDisabled?: boolean;
  isLarge?: boolean;
  showCancelButton?: string;
  modalClassName?: string;
  modalCloseIcon?: IconProp;
  isFullscreen?: boolean;
  dialogContentRef?: unknown;
  modalClose?: string;
  sx?: SxProps<Theme>;
  actionButtonsOrder?: "confirm-cancel" | "cancel-confirm";
  actionButtonProps?: LoadingButtonProps;
}

const ModalWindow = ({
  showModal,
  modalClose,
  setShowModal,
  headerText,
  bodyText,
  renderBody,
  onActionButtonPress,
  actionButtonType,
  actionButtonText,
  cancelButtonText,
  onCancelButtonPress,
  confirmDisabled,
  isLarge,
  showCancelButton,
  modalClassName,
  isFullscreen,
  modalCloseIcon,
  dialogContentRef,
  sx,
  actionButtonsOrder = "confirm-cancel",
  actionButtonProps,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const onActionButtonPressHandle = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsLoading(true);
    try {
      await onActionButtonPress?.();
    } finally {
      setIsLoading(false);
    }
  };

  const onCancelButtonPressHandle = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onCancelButtonPress) {
      setIsLoading(true);
      try {
        await onCancelButtonPress();
      } finally {
        setIsLoading(false);
      }
    } else {
      setShowModal(false);
    }
  };

  return (
    <Dialog
      open={showModal}
      onClose={() => setShowModal(false)}
      fullWidth
      fullScreen={isFullscreen}
      maxWidth={`${isLarge ? "lg" : "sm"}`}
      className={`modal-container ${modalClassName ? modalClassName : ""}`}
      sx={{ ...sx }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: 2,
          borderBottom: `1px solid ${pinwheelPalette.lightGrey[100]}`,
          minHeight: "auto",
        }}
      >
        {typeof headerText === "string" ? (
          <Typography variant="h2" color={(theme)=> theme.palette.text.primary} sx={{ pr: 1 }} data-cy="modal-header">
            {headerText}
          </Typography>
        ) : (
          headerText
        )}
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setShowModal(false);
          }}
          data-cy={modalClose}
          sx={{ height: "1em", width: "1em", alignSelf: "flex-start" }}
        >
          <FontAwesomeIcon icon={modalCloseIcon ? modalCloseIcon : faXmark} />
        </IconButton>
      </Toolbar>
      <DialogContent ref={dialogContentRef as React.RefObject<HTMLDivElement>}>
        {renderBody ? renderBody() : <Typography variant="body1">{bodyText}</Typography>}
      </DialogContent>
      {(onActionButtonPress || !showCancelButton) && (
        <DialogActions
          sx={{
            borderTop: `1px solid ${pinwheelPalette.lightGrey[100]}`,
            display: "flex",
            justifyContent: actionButtonsOrder === "confirm-cancel" ? "flex-end" : "flex-start",
            padding: 2,
            flexDirection: actionButtonsOrder === "confirm-cancel" ? "row" : "row-reverse",
            gap: 2,
            "& :not(:first-of-type)": {
              marginLeft: 0,
            },
          }}
        >
          {onActionButtonPress && (
            <LoadingButton
              variant="contained"
              color={actionButtonType}
              onClick={onActionButtonPressHandle}
              loading={isLoading}
              disabled={confirmDisabled || isLoading}
              className="action-btn"
              {...actionButtonProps}
            >
              {actionButtonText ? actionButtonText : "Confirm"}
            </LoadingButton>
          )}
          {!showCancelButton && (
            <Button
              variant="text"
              onClick={onCancelButtonPressHandle}
              disabled={isLoading}
              className="cancel-btn"
            >
              {cancelButtonText ? cancelButtonText : "Cancel"}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ModalWindow;
