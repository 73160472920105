import { Box, InputAdornment, styled, TextField, Typography } from "@mui/material";
import parsePhoneNumberFromString, { AsYouType, CountryCode, getExampleNumber } from "libphonenumber-js";
import examples from "libphonenumber-js/mobile/examples";
import { useCallback, useMemo, useRef, useState } from "react";
import { CountryCodeSelector } from "./country-code-selector";
import { formatPhoneNumberByCountry, isShortCodeNumber, parsePhoneNumberByCountry } from "@/util/phone-number-utils";

const PhoneNumberInputBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  flexDirection: "column",
}));

const PhoneAdornment = styled(InputAdornment)(({ theme }) => ({
  height: "54px",
  maxHeight: "100%",
  color: "inherit",
  margin: 0,
  padding: "0 14px 0 0",
  borderRight: `1px solid ${theme.palette.divider}`,
}));

export interface PhoneNumberInfo {
  countryCode: string;
  nationalNumber: string;
  e164Number: string;
  isValid: boolean;
}

interface PhoneNumberInputProps {
  defaultValue?: string;
  onChange?: (value: string, info: PhoneNumberInfo) => void;
  label: string;
  disabled?: boolean;
  isError?: boolean;
  showExample?: boolean;
  countryCode?: string;
  countryCodeSelector?: boolean;
}

export const PhoneNumberInput = (props: PhoneNumberInputProps) => {
  return <PhoneNumberInputInner key={props.defaultValue} {...props} />;
};

const PhoneNumberInputInner = ({
  defaultValue = "",
  onChange,
  label,
  disabled,
  isError,
  showExample,
  countryCode,
  countryCodeSelector,
}: PhoneNumberInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [selectedCountryCode, setSelectedCountryCode] = useState<CountryCode>(countryCode ? (countryCode as CountryCode) : "US");

  const formattedDefaultValue = useMemo(() => {
    if (!defaultValue) {
      return "";
    }
    if (isShortCodeNumber(defaultValue)) {
      return defaultValue;
    }
    return formatPhoneNumberByCountry(defaultValue, selectedCountryCode);
  }, [defaultValue, selectedCountryCode]);

  const exampleNumber = useMemo(() => getExampleNumber(selectedCountryCode, examples)?.formatNational() || "", [selectedCountryCode]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      let formattedValue = newValue;

      if (!isShortCodeNumber(newValue) && newValue.length >= 8) {
        const asYouType = new AsYouType(selectedCountryCode);
        formattedValue = asYouType.input(newValue);
      }

      if (inputRef.current) {
        inputRef.current.value = formattedValue;
      }

      if (onChange) {
        const info = parsePhoneNumberByCountry(newValue, selectedCountryCode);
        onChange(info.e164Number, info);
      }
    },
    [onChange, selectedCountryCode],
  );

  const handleCountryChange = useCallback(
    (newCountryCode: CountryCode) => {
      setSelectedCountryCode(newCountryCode);
      if (inputRef.current) {
        const parsedNumber = parsePhoneNumberFromString(inputRef.current.value, newCountryCode);
        if (parsedNumber) {
          inputRef.current.value = parsedNumber.formatNational();
        }
      }

      onChange?.(inputRef.current?.value || "", parsePhoneNumberByCountry(inputRef.current?.value || "", newCountryCode));
      inputRef.current?.focus();
    },
    [onChange],
  );

  return (
    <PhoneNumberInputBox>
      <TextField
        inputRef={inputRef}
        type="tel"
        fullWidth
        size="medium"
        defaultValue={formattedDefaultValue}
        onChange={handleChange}
        disabled={disabled}
        placeholder={exampleNumber}
        label={label}
        className="contact-number"
        inputProps={{ "data-cy": "contactNumberInput" }}
        color={isError ? "error" : "primary"}
        InputProps={
          countryCodeSelector
            ? {
                startAdornment: (
                  <PhoneAdornment position="start">
                    <CountryCodeSelector countryCode={selectedCountryCode} onSelect={handleCountryChange} />
                  </PhoneAdornment>
                ),
              }
            : undefined
        }
      />
      {showExample && exampleNumber ? <Typography variant="caption">For example: {exampleNumber}</Typography> : null}
    </PhoneNumberInputBox>
  );
};
